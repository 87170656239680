// Third party libraries
import _ from 'lodash';
import React, { createRef } from 'react';

// Components
import FixedBanner from '../../../components/fixedBanner';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';
import Notification from '../../../components/notificaction';
import SubMenu from '../../../components/navbar/submenu';
import TopBar from '../../../components/navbar/topbar';
import PlayerButtons from '../components/playerButtons';
import PlayerTimer from '../components/playerTimer';
import NextSlide from '../components/nextSlide';
import Preambule from '../components/preambule';
import ProgressBarSeeker from '../components/progressBarSeeker';
import { Responsive } from 'semantic-ui-react';

// Utils
import UseNoSleep from '../../../../utils/noSleep';
import { TrackingService } from '../../../../utils/TrackingService';
import { changeScreenOrientation, completeSession, showHealthAdvise, onRestart } from '../../../../utils/practice';

// Models
import SessionModel from '../../../../data/models/session/session';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../practice.scss';

// Assets
import Poster from '../../../assets/img/poster.jpg';

class PracticeImage extends React.Component {

    videoPlayersCounter = 5;
    intervalCountDown = 0;
    song = null;
    videoWidthSufix = Math.max(window.innerHeight, window.innerWidth) > 1280 ? '' : '_720';
    videos = [];
    currentVideo = 0;
    videoplayers = [];
    language = window.atob(this.props.match.params.sequence).split('/')[1];
    startOnPortrait = false;

    songPlayed = false;

    instructionPlayed = false;

    backgroundAudio = null;

    asanasCount = 0;

    constructor(props) {

        super(props);

        let globalSequenceTime = 0;
        _.each(this.props.data.routine, routine => {

            globalSequenceTime += _.parseInt(routine.duration);

        });

        _.each(this.props.data.routine, (asana, index) => {

            let beep = false;
            if (asana.duration > 0) {

                this.videos.push({
                    mov: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                    mp4: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                    webm: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                    loop: true,
                    duration: asana.duration,
                    currentAsanaIndex: index,
                    image: asana.image
                });

                this.asanasCount++;

            } else {

                beep = true;

            }

            _.each(asana.transitionVideos, transitionVideo => {

                this.videos.push({
                    mov: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                    mp4: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                    webm: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                    loop: false,
                    beep
                });

                beep = false;

            });

        });

        this.song = new Audio(this.props.data.routine[0].nameAudio);

        const videoPlayerSources = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            videoPlayerSources.push(this.videos[i]);

        }

        this.state = {
            isFull: false,
            data: this.props.data,
            elapsedTime: 0,
            startTime: 0,
            firstStart: false,
            globalProgress: 0,
            globalSequenceTime,
            showPreambule: false,
            showNextSlide: false,
            status: 'idle',
            startTimePreambule: 0,
            userSessionId: '',
            indicators: {
                flexoextensionIndicator: this.props.bioMetricMatrix.flexoextensionIndicator || 0,
                lateralizationIndicator: this.props.bioMetricMatrix.lateralizationIndicator || 0,
                abductionAductionIndicator: this.props.bioMetricMatrix.abductionAductionIndicator || 0,
                muscularTrainIndicator: this.props.bioMetricMatrix.muscularTrainIndicator || 0
            },
            currentPlayer: 0,
            videoPlayerSources,
            isOnTransitionVideos: false,
            isLoading: false,
            indexAsanasRealized: new Set([])
        };

        this.triggerRef = createRef();

        this.videoRefs = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            this.videoRefs[i] = React.createRef();

        }

    }

    componentWillUnmount() {

        clearInterval(this.intervalCountDown);
        clearInterval(window['yogabotIntervalsPracticePreambule']);
        delete window['yogabotIntervalsPracticePreambule'];
        this.props.closeGenericModal();
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

        if(this.backgroundAudio) {
            this.backgroundAudio.pause();
            this.backgroundAudio.currentTime = 0;
            this.backgroundAudio.src = '';
            this.backgroundAudio = null;
        }

    }

    componentDidMount() {

        this.preloadVideos();

        showHealthAdvise(this.props.sessionTime, this.props.openGenericModal, this.props.closeGenericModal);

        for (let i = 0; i < this.videoPlayersCounter; i++) {

            this.videoRefs[i].current.setAttribute('webkit-playsinline', true);

        }

        this.selectNatureBackgroundAudio();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.backgroundAudio, this.props.backgroundAudio)) {
            this.selectNatureBackgroundAudio();
        }

    }

    selectNatureBackgroundAudio = () => {

        if(this.props.backgroundAudio === 'nature') {
            // Se ponen los volúmenes independientes para cada sonido porque puede que alguno se escuche menos que otro por defecto
            // para poder equilibrar el sonido
            const naturalSound = {
                surf: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/surf.mp3',
                    volume: 0.2,
                },
                waterfall: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/waterfall.mp3',
                    volume: 0.1,
                },
                water: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/water.mp3',
                    volume: 0.3,
                },
                birds: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/birds.mp3',
                    volume: 0.1,
                },
            };

            const keys = Object.keys(naturalSound);
            const randomKey = keys[Math.floor(Math.random() * keys.length)];
            const randomValue = naturalSound[randomKey];

            try {
                this.backgroundAudio = new Audio();
                this.backgroundAudio.src = randomValue.src;
                this.backgroundAudio.loop = true;
                this.backgroundAudio.volume = randomValue.volume;
                this.backgroundAudio.play();
            } catch (error) {
                console.error(error);
            }       
        }

    }

    selectInstructionsBackgroundAudio = (index = 0) => {
        setTimeout(()=>{
            if(!this.instructionPlayed && this.props.backgroundAudio === 'instructions'){
                try {
                    if(this.backgroundAudio){
                        this.backgroundAudio.pause();
                        this.backgroundAudio.currentTime = 0;
                        this.backgroundAudio.src = '';
                        this.backgroundAudio = null;
                    }
        
                    this.backgroundAudio = new Audio();
                    this.backgroundAudio.src = this.props.data.routine[index].technicalAudio;
                    this.backgroundAudio.loop = false;
                    this.backgroundAudio.volume = 1;
                    this.backgroundAudio.play();
                } catch (error) {
                    console.error(error);
                }
    
                this.instructionPlayed = true;
            }
        }, 600)
    }

    onPlayVideo = () => {

        if(!this.songPlayed) {
            
            if ((this.videos[this.currentVideo].loop || this.videos[this.currentVideo].beep) && this.currentVideo > 0) {

                this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/bell.mp3';
                this.song.play();
                
                this.selectInstructionsBackgroundAudio(this.currentVideo);
    
                this.songPlayed = true;
    
            }
        }
        
        if (this.videos[this.currentVideo].loop) {

            const elapsedTime = this.state.elapsedTime ? this.state.elapsedTime : this.videos[this.currentVideo].duration;

            let sumEight = 8;

            if(this.state.status === 'playing'){
                sumEight = 0;
            }

            const startTime = this.state.elapsedTime ? Math.floor(Date.now() - ((this.videos[this.currentVideo].duration - sumEight - elapsedTime) * 1000)) : Date.now();

            this.setState({ status: 'playing', elapsedTime, startTime, isOnTransitionVideos: false });

            clearInterval(this.intervalCountDown);
            this.intervalCountDown = setInterval(() => {

                let elapsed = Math.floor((Date.now() - this.state.startTime) / 1000);

                if (elapsed < 0) {
                    elapsed = 0;
                }

                this.setState({ elapsedTime: this.videos[this.currentVideo].duration - elapsed });

                if (this.videos[this.currentVideo].duration - (Math.floor((Date.now() - this.state.startTime) / 1000)) <= 0) {

                    clearInterval(this.intervalCountDown);
                    this.videoplayers[this.state.currentPlayer].pause();
                    this.onVideoEnded();

                } else if (!this.state.showNextSlide
                    && _.get(this.state.data.routine, '[' + (this.videos[this.currentVideo].currentAsanaIndex + 1) + '].duration', false)
                    && this.videos[this.currentVideo].duration - (Math.floor((Date.now() - this.state.startTime) / 1000)) <= 10) {

                    if (this.videos[this.currentVideo].duration > 10) {

                        this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/ES/next.mp3';
                        this.song.addEventListener('ended', () => {

                            this.song = this.song.cloneNode(true);
                            this.song.src = this.state.data.routine[this.videos[this.currentVideo].currentAsanaIndex + 1].nameAudio;
                            this.song.play();

                        }, false);
                        
                        if(this.backgroundAudio){
                            this.backgroundAudio.volume = 0.2;
                        }

                        this.song.play();

                    } else if (this.videos[this.currentVideo].duration >= 5) {

                        this.song.src = this.state.data.routine[this.videos[this.currentVideo].currentAsanaIndex + 1].nameAudio;
                        this.song.play();

                    }

                    this.setState({ showNextSlide: true });

                }

            }, 500);

        } else {

            this.setState({ isOnTransitionVideos: true });

        }

    }

    onVideoEnded = () => {

        if (this.currentVideo < this.videos.length - 1) {

            this.currentVideo++;
            let endedPlayer = this.state.currentPlayer;
            let playerToGo = endedPlayer === this.videoPlayersCounter - 1 ? 0 : endedPlayer + 1;

            let nextVideoToLoad = this.currentVideo + this.videoPlayersCounter - 1 < this.videos.length ? this.currentVideo + this.videoPlayersCounter - 1 : 0;
            const videoPlayerSources = _.cloneDeep(this.state.videoPlayerSources);
            videoPlayerSources[endedPlayer] = this.videos[nextVideoToLoad];
            this.downloadVideo(nextVideoToLoad, endedPlayer);

            const asanaIndex = this.videos[this.currentVideo - 1].currentAsanaIndex;

            let globalProgress = this.state.globalProgress;
  
            // Esto es para que un asana que ya se realizó no vuelva a sumar al progreso de la práctica
            let indexAsanasRealized = this.state.indexAsanasRealized;

            if(this.videos[this.currentVideo - 1].loop){

                if(!indexAsanasRealized.has(asanaIndex)) {

                    globalProgress = globalProgress + this.state.data.routine[asanaIndex].duration;

                } 
                
                indexAsanasRealized.add(asanaIndex)
            }

            this.setState({
                currentPlayer: playerToGo,
                videoPlayerSources,
                globalProgress,
                showNextSlide: false,
                elapsedTime: 0,
                indexAsanasRealized
            }, () => this.videoplayers[endedPlayer].load());

            this.videoplayers[playerToGo].play();

            if (this.videos[this.currentVideo - 1].loop) {

                try {

                    SessionModel.onAsanaFinish({
                        userSessionId: this.state.userSessionId,
                        timeElapsed: this.state.data.routine[this.videos[this.currentVideo - 1].currentAsanaIndex].duration
                    }).then(() => {

                        this.props.getLastWeekTrainingSeconds();

                    });

                } catch (error) {

                    console.error('Error:onAsanaFinish: ', error);

                }

            }

            if(this.videos[this.currentVideo - 1].loop) {
                this.songPlayed = false;
                this.instructionPlayed = false;
            }

        } else {
            const asanaIndex = this.videos[this.videos.length - 1].currentAsanaIndex;

            this.state.indexAsanasRealized.add(asanaIndex);

            if(this.state.indexAsanasRealized.size === this.asanasCount){
                
                this.setState({
                    globalProgress: this.state.globalSequenceTime,
                    showNextSlide: false,
                    elapsedTime: 0,
                    status: 'idle',
                    showPreambule: false
                });
                completeSession(this.state.userSessionId, this.props.history, this.language, '/practice/result/sequence', this.props.getLastWeekTrainingSeconds);
    
                this.songPlayed = false;
                this.instructionPlayed = false;

            } else {
                const globalProgress = this.state.globalProgress + this.state.data.routine[asanaIndex].duration;

                this.setState({ globalProgress, elapsedTime: 0 });
                this.onForwardAndBackward(0);
                this.onPause();
            }

        }

    }

    preloadVideos = (start = 0, callback = null) => {
        let count = start;
 
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            const video = document.getElementById('video_' + i);

            if(!video) return;

            this.videoplayers.push(video);
            this.videoplayers[i].addEventListener('play', this.onPlayVideo);
            this.videoplayers[i].addEventListener('ended', this.onVideoEnded);
            this.downloadVideo(count, i, callback);

            count++;
        }

    }

    downloadVideo = (videoIndex, playerIndex, callback = null) => {

        const video = this.videos[videoIndex];

        if(!video) return;

        let url = video.mov;
        if (window.Modernizr.video && (window.Modernizr.video.webm === 'probably' || (window.Modernizr.video.webm === 'maybe' && window.Modernizr.video.h264 !== 'probably'))) {

            url = video.webm;

        } else if (window.Modernizr.video && ['probably', 'maybe'].indexOf(window.Modernizr.video.h264)) {

            url = video.mp4;

        }
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "blob";
        const that = this;
        request.onload = function () {

            if (this.status === 200) {

                // Ya no me sirve esta condicional porque ahora me puedo mover a donde quiera
                // if (that.currentVideo < videoIndex || (videoIndex === 0 && !that.intervalCountDown && !that.state.showPreambule)) {
                    const blob = URL.createObjectURL(this.response)

                    that.videoplayers[playerIndex].src = blob;
                    if(that.videoplayers?.[playerIndex]?.load) that.videoplayers[playerIndex].load();

                    // Esto es para saber cuando se carga el primer video (playerIndex = 0) del paginado de 5 para poder darle play() sabiendo que ya existe.
                    if(playerIndex === 0 && callback) callback();
                    
                // }

            }

        }

        request.send();

    }

    restart = () => {

        this.setState({ firstStart: false, globalProgress: 0, currentAsanaIndex: 0, showNextSlide: false, elapsedTime: 0, status: 'idle', showPreambule: false, isFull: false });
        this.preloadVideos();
        clearInterval(this.intervalCountDown);

    }

    onStart = async () => {

        TrackingService.registerEvent('Practice', 'practiceSequenceStart');

        try {

            // Antes en vez de poner this.videos[this.currentVideo].currentAsanaIndex se ponía 0, pero ahora se puede empezar por cualquier asana, y no necesariamente por la 0.
            this.song = new Audio(this.props.data.routine[this.videos[this.currentVideo].currentAsanaIndex].nameAudio);
            this.song.play();

            this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

            const response = await SessionModel.startSession({ sequence: this.props.sequence });

            this.setState({ userSessionId: response.data._id, firstStart: true, isFull: true, showPreambule: true, startTimePreambule: Date.now() });
            this.videos[this.currentVideo].duration += 8;
            this.videoplayers[this.state.currentPlayer].play();

        } catch (error) {

            console.error('Error:onStart: ', error);

        }

    }

    onPause = () => {

        clearInterval(this.intervalCountDown);
        this.videoplayers[this.state.currentPlayer].pause();
        this.setState({ status: 'resume', isFull: false });
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    onResume = () => {

        this.setState({ isFull: true, showPreambule: true, startTimePreambule: Date.now() });
        this.videoplayers[this.state.currentPlayer].play();
        this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

    }

    onForwardAndBackward = (index) => {

        this.currentVideo = index;

        this.songPlayed = false;
        this.instructionPlayed = false;

        const videoPlayerSources = [];

        let end = this.videoPlayersCounter + index;

        if(end >= this.videos.length ){
            end = this.videos.length;
        }

        // Esto es porque al hacer onStart al principio (en el primer asana) se suman 8 segundos
        // a la duración para incluir la cuenta regresiva, pero ahora no debe suceder eso.
        if(index === 0){
            this.videos[index].duration -= 8;
        }

        for (let i = index; i < end; i++) {
            videoPlayerSources.push(this.videos[i]);
        }

        this.setState({
            videoPlayerSources,
            currentPlayer: 0,
            showNextSlide: false,
            elapsedTime: 0
        }, ()=>{

            this.setState({ isLoading: true });

            clearInterval(this.intervalCountDown);
            this.videoplayers[0].pause();

            this.videoplayers = [];
            
            this.preloadVideos(index, () => {

                this.videoplayers[this.state.currentPlayer].load();

                if(this.state.status === 'playing'){
                    this.videoplayers[0].play();
                } else if(this.state.status === 'resume'){
                    this.videoplayers[0].pause();
                }

                this.setState({ isLoading: false });
            });

        }); 
    }

    gotoDiscoverMore = () => this.props.history.push(`/practiceDiscover/targets/${this.props.match.params.sequence}`);

    renderVideoPlayers = () => {

        const playersRenderization = [];

        let end = this.videoPlayersCounter;

        if(end >= this.state.videoPlayerSources.length){
            end = this.state.videoPlayerSources.length;
        }

        for (let i = 0; i < end; i++) {

            playersRenderization.push(i);

        }

        return playersRenderization.map(i =>

            <video poster={Poster} ref={ this.videoRefs[i] } playsInline key={ 'video_' + i } style={{ display: this.state.currentPlayer === i ? 'block' : 'none' }} muted preload="auto" id={ 'video_' + i } loop={ this.state.videoPlayerSources[i].loop }>
                <source src={ this.state.videoPlayerSources[i].webm } type="video/webm" />
                <source src={ this.state.videoPlayerSources[i].mp4 } type="video/mp4" />
                <source src={ this.state.videoPlayerSources[i].mov } />
                Your browser does not support the video tag.
            </video>

        );

    }

    render() {

        const { isFull, isLoading } = this.state;
        const { isAlternative, windowMeassures : { height } } = this.props;

        return (
            <React.Fragment>
                <UseNoSleep />
                <GradientNav active="practice" { ...this.props } />
                { this.state.data ? (
                    <div className="wrapper-practice">
                        <TopBar {...this.props} callback={()=>this.props.history.push('/practiceList')} text={I18n.t('practice.practice')} />
                        <FixedBanner />
                        <div className="inner">
                            {!isAlternative && <div className="header-message">
                                <div className="header-content">
                                    <div className="left">
                                        { this.props.bioMetricMatrix.progress > 0 ? <p dangerouslySetInnerHTML={{__html: I18n.t('practice.welcomeMessage',{ userName: _.get(this.props,'userName', '') })} }></p> :
                                        <p dangerouslySetInnerHTML={{ __html: I18n.t('practice.welcomeMessageAlternative', { userName: _.get(this.props,'userName', '') }) }}></p>}
                                    </div>
                                </div>
                            </div>}
                            <div ref={this.triggerRef} className={ isFull ? 'fullscreen-background' : '' }>
                                <div className={ 'wrapper-slides ' + (isFull ? 'fullscreen-enabled' : '') } style={{width:'100%', maxWidth: isFull ? `${height * (16 / 9)}px` : '100%' }}>
                                    <div className="wrapper-slide">
                                        <Preambule
                                            visible={ this.state.showPreambule }
                                            startTimePreambule={ this.state.startTimePreambule }
                                            callback={ () => {
                                                this.setState({ showPreambule: false });
                                                this.selectInstructionsBackgroundAudio(this.currentVideo);
                                            } }
                                        />
                                        <div className={ "content " + (this.state.showPreambule ? 'blurred': '') }>
                                            <PlayerButtons
                                                visible={ !this.state.showPreambule && !this.state.isOnTransitionVideos }
                                                status={ this.state.status }
                                                firstStart={ this.state.firstStart }
                                                onStart={ this.onStart }
                                                onRestart={ () => onRestart(this.props.openGenericModal, this.props.closeGenericModal, this.onPause, this.restart, this.onResume) }
                                                onPause={ this.onPause }
                                                onResume={ this.onResume }
                                            />
                                            <PlayerTimer
                                                visible={ !this.state.showPreambule && !this.state.isOnTransitionVideos }
                                                slideElapsedTime={ this.state.elapsedTime }
                                            />
                                            { this.renderVideoPlayers() }
                                            <NextSlide
                                                visible={ this.state.showNextSlide }
                                                currentSlideIndex={ this.videos[this.currentVideo].currentAsanaIndex }
                                                routine={ this.state.data.routine }
                                            />{
                                            isFull && 
                                                <>
                                                <Responsive minWidth={950} as={React.Fragment}>
                                                    <ProgressBarSeeker
                                                        globalProgress={ this.state.globalProgress }
                                                        globalSequenceTime={ this.state.globalSequenceTime }
                                                        videos={this.videos}
                                                        startLimit={0.07}
                                                        endLimit={0.15}
                                                        startLimitPercent={15}
                                                        endLimitPercent={80}
                                                        styles={{position: 'absolute', bottom: 0, zIndex: 9}}
                                                        onForwardAndBackward={this.onForwardAndBackward}
                                                        triggerRef={this.triggerRef}
                                                    />
                                                </Responsive>
                                                 <Responsive maxWidth={949} as={React.Fragment}>
                                                    <ProgressBarSeeker
                                                        globalProgress={ this.state.globalProgress }
                                                        globalSequenceTime={ this.state.globalSequenceTime }
                                                        videos={this.videos}
                                                        startLimit={0.05}
                                                        endLimit={0.27}
                                                        startLimitPercent={15}
                                                        endLimitPercent={80}
                                                        styles={{position: 'absolute', bottom: 0, zIndex: 9}}
                                                        onForwardAndBackward={this.onForwardAndBackward}
                                                        triggerRef={this.triggerRef}
                                                    />
                                                </Responsive>
                                             </>
                                            }
                                            <div className="loading-container" hidden={!isLoading}>
                                                <div className="loading-content">
                                                    <div className="loading-text">
                                                        <p>{I18n.t('practice.loading')}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Responsive minWidth={950} as={React.Fragment}>
                                <ProgressBarSeeker
                                    globalProgress={ this.state.globalProgress }
                                    globalSequenceTime={ this.state.globalSequenceTime }
                                    videos={this.videos}
                                    startLimit={0.07}
                                    endLimit={0.17}
                                    startLimitPercent={15}
                                    endLimitPercent={80}
                                    onForwardAndBackward={this.onForwardAndBackward}
                                />
                            </Responsive>
                            <Responsive maxWidth={949} as={React.Fragment}>
                                <ProgressBarSeeker
                                    globalProgress={ this.state.globalProgress }
                                    globalSequenceTime={ this.state.globalSequenceTime }
                                    videos={this.videos}
                                    startLimit={0.07}
                                    endLimit={0.27}
                                    startLimitPercent={15}
                                    endLimitPercent={80}
                                    onForwardAndBackward={this.onForwardAndBackward}
                                />
                            </Responsive>
                        </div>
                        <div className="footer-separator"></div>
                        <Footer { ...this.props } type="branded"/>
                    </div>
                ) : '' }
                <SubMenu active="practice" { ...this.props } />
                <Notification  type="fixered" />
            </React.Fragment>
        );

    }

}

export default PracticeImage;