// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Icon, Image, Responsive } from 'semantic-ui-react';
import moment from 'moment';
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Components
import FixedBanner from '../../../../components/fixedBanner';
import Footer from '../../../../components/footer';
import GradientNav from '../../../../components/navbar/gradient';
import SubMenu from '../../../../components/navbar/submenu';
import TopBar from '../../../../components/navbar/topbar';

// Styled components
import OButton from '../../../../styled/components/button';
import { InnerWrapper, Wrapper } from '../../../../styled/components/wrappers';

// Models
import SessionModel from '../../../../../data/models/session/session';

// Config
import Config from '../../../../../config';

// Locales
import I18n from '../../../../../i18n';

// Assets
import DemoImage from '../../../../assets/img/yogabot_icon.svg';

// Styles
import './practiceDetail.scss';

class PracticeDetail extends React.Component {

    constructor(props) {

        super(props);

        this.videoRef = React.createRef();
        this.state = {
            activeVideoPresentation: false,
            sequence: {},
            categories: []
        };

    }

    componentDidMount() {

        this.videoRef.current && this.videoRef.current.setAttribute('webkit-playsinline', true);
        this.getData();

    }

    getData = async () => {

        this.props.openLoader();

        const sequence = await SessionModel.getSequence(this.props.match.params.practiceId);
        const categories = await SessionModel.getSequenceCategories();
        this.setState({ sequence, categories: categories.data });

        this.props.closeLoader();

    }

    setMetaItems = (types, level, duration, asanasCount, category) => {

        const result = [ ];

        if (!_.isEmpty(category)) {

            result.push(I18n.t('practice.category', { category }));

        }

        result.push(I18n.t('practice.asanas') + ' ' + asanasCount )

        if (duration) {

            result.push(I18n.t('practice.durationParams', { duration }));

        }

        if (_.size(types)) {

            _.each(_.pull(types, 'none'), p => result.push(I18n.t(`practice.${ p }`)));

        }

        return _.map(result, (item, index) => <div key={ index } className='related-item'>{ item }</div>);

    }

    onTogglePreview = () => this.setState(prevState => ({ activeVideoPresentation: !prevState.activeVideoPresentation }));

    practiceNow = () => {

        const { userConfig } = this.props;
        const { sequence } = this.state;

        const sequenceData = window.btoa(sequence.code + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + true);
        this.props.history.push(`/practice/${ sequenceData }`);

    };

    getTargetDescription = target => {

        if (target.includes('.')) {

            const targetGroup = target.substring(0, target.indexOf('.'));
            const targetSubGroup = target.substring(target.indexOf('.') + 1);

            if (['makeMoreFlexible', 'toneUp'].includes(targetGroup)) {

                return I18n.t(`profile.objectivesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.objectivesDescriptions.${ targetGroup }.childs.${ targetSubGroup }`);

            } else {

                if (targetSubGroup.includes('.')) {

                    const injuryType = targetSubGroup.substring(0, targetSubGroup.indexOf('.'));
                    let injurySubtype = targetSubGroup.substring(targetSubGroup.indexOf('.') + 1);
                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.childs.${ injurySubtype }`);

                } else {

                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ targetSubGroup }.label`);

                }

            }

        } else {

            return target;

        }

    }

    getSequenceDuration = (sequence) => {

        const routine = sequence.routine;

        let duration = 0;

        if (!_.isEmpty(routine)) {

            _.each(routine, routineItem => duration += routineItem.duration);

        }

        return Math.floor(moment.duration(duration, 'seconds').asMinutes()) + ' min';
    }

    getCategory = (categories, categoryId) => _.find(categories, category => category._id === categoryId);

    render() {

        const { sequence, categories } = this.state;
        const { title, targets, description, types, level, asanasCount, videoUrl, thumbUrl } = sequence;
        const { activeVideoPresentation } = this.state;

        const duration = this.getSequenceDuration(sequence);

        const category = this.getCategory(categories, categories?.[0]?._id);

        return _.isEmpty(sequence) ? '' : (
            <>
                <GradientNav active='practice' { ...this.props } />
                <Wrapper>
                    <TopBar { ...this.props } callback={ () => this.props.history.push('/practiceList') } text={ title.ES } />
                    <FixedBanner />
                    <InnerWrapper style={{maxWidth: 1240, paddingTop: '3rem'}}>
                        <section>
                            <div className='i-back' style={{ padding: '15px 15px 30px 0' }} onClick={ () => this.props.history.push('/practiceList') }>
                                <Icon name='angle left' color='black'/>
                                <strong>{ I18n.t('actions.back') }</strong>
                            </div>
                        </section>
                        <section className='content-section'>
                            <div className='background-wrapper'>
                                <Image src={ thumbUrl || DemoImage } />
                            </div>
                            { (activeVideoPresentation && videoUrl.length > 0) &&
                                <div className='preview-iframe'>
                                    <Icon name='close' className='close-preview' size='big' onClickCapture={ this.onTogglePreview }></Icon>
                                    <div className='preview-expanded-16-9' >
                                        <iframe title='vimeo' src={ Config.player.path + window.btoa(videoUrl) } width='100%' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen></iframe>
                                    </div>
                                </div>
                            }
                            <div className='content-section-wrapper'>
                                <div className='master-crump'>
                                    <strong className='display-subtitle-4'>{ I18n.t('practice.practice') } / { title.ES }</strong>
                                </div>
                                <div>
                                    <h1>
                                        { title.ES }
                                    </h1>
                                </div>
                                <div className='master-ctas'>
                                    <Responsive minWidth='720' as={React.Fragment}>
                                        <OButton size='huge' primary type='button' color='#fff' onClickCapture={ this.practiceNow }>
                                            <span>{ I18n.t('actions.startSequence') }</span>
                                        </OButton>
                                        {
                                            !_.isEmpty(videoUrl) && 
                                            <OButton size='huge' terciary type='button' color='#30D0B0' onClick={ this.onTogglePreview } style={{ margin: '0 0 0 15px' }}>
                                                <span>{ I18n.t('actions.startPreview') }</span>
                                            </OButton>
                                        }
                                    </Responsive>
                                    <Responsive maxWidth='719' as={React.Fragment}>
                                        <OButton fluid size='large' primary type='button' color='#fff' style={{ margin: '0 0 10px 0' }} onClickCapture={ this.practiceNow }>
                                            <span>{ I18n.t('actions.startSequence') }</span>
                                        </OButton>
                                        {
                                            !_.isEmpty(videoUrl) && 
                                            <OButton fluid size='large' terciary type='button' color='#30D0B0' onClick={ this.onTogglePreview } >
                                                <span>{ I18n.t('actions.startPreview') }</span>
                                            </OButton>
                                        }
                                    </Responsive>
                                </div>
                                <div className='master-description'>
                                    <p className='display-subtitle-2'>{ I18n.t('practice.practiceDescription') }</p>
                                    <pre className='display-subtitle-1' dangerouslySetInnerHTML={{ __html: description['ES'] }}></pre>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='master-related'>
                                <p className='display-subtitle-2'>{ I18n.t('practice.relatedContent') }</p>
                                <div className='related-items'>
                                    { this.setMetaItems(types, level, duration, asanasCount, category?.label) }
                                </div>
                            </div>
                        </section>
                        <section>
                            { _.isEmpty(targets) ? '' :
                                <div className='master-targets'>
                                    <p className='display-subtitle-2'>{ I18n.t('practice.sequenceTargets') }</p>
                                    <div className='targets-wrapper'>
                                        { (targets || []).map((target) => (
                                            <p key={target}>{ this.getTargetDescription(target) }.&nbsp;</p>
                                        )) }
                                    </div>
                                </div>
                            }
                        </section>
                    </InnerWrapper>
                    <div className='footer-spacer'></div>
                    <Footer { ...this.props } type='branded' style={{ zIndex: 2 }}/>
                </Wrapper>
                <SubMenu active='practice' { ...this.props }/>
            </>
        );

    }

}

const mapStateToProps = state => {

    return {
        userConfig: _.get(state,'auth.userSession.config')
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader })(PracticeDetail);