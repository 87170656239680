// Third Party libraries
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// Hooks
import useTutorial from './useTutorial';

const TutorialStarter = ({ name, steps }) => {
  const userSession = useSelector(
    (state) =>
      state.auth.userSession
  );

  const { isDone, startTutorial } = useTutorial(name, steps);

  useEffect(() => {
    if (userSession) {
      if (!isDone && !_.isEmpty(steps)) {
        startTutorial();
      }
    }
  }, [isDone, startTutorial, steps, userSession]);

  return null;
};

export default TutorialStarter;
