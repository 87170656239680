import React, { Component } from 'react'
import styled from 'styled-components';

export const IframWrapper = styled.div`
    position:fixed;
    width:100%;
    height:100%;
    z-index:9999;
    background:black;
`;

export default class Player extends Component {

    render() {

        const videoUrl = window.atob(this.props.match.params.url) + '?title=0&byline=0&portrait=0&dnt=1&end=0&loop=1';
        
        return <IframWrapper>
            { videoUrl.includes('vimeo') ?
                <iframe title='vimeoIframe' src={ videoUrl } width='100%' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen></iframe>
                : <video src={ videoUrl } width='100%' controls style={{ top: '50%', position: 'relative', transform: 'translateY(-50%)' }} />
            }
        </IframWrapper>;

    }

}