// Third party library
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

// Components
import Matriz from '../matriz';
import Indicators from './components/indicators';
import { Responsive } from 'semantic-ui-react';

// Redux store
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';

// Styles
import { FrameContainer, FrameIndicator, MatrixBodies } from './styles';
import './styles/styles.scss';

// Locales
import I18n from '../../../i18n';

class BiometricMatrix extends Component {

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    render() {

        const { bioMetricMatrixData, userSession } = this.props;

        return (
            <div>
                <p style={{ marginBlock: '15px', fontSize: '1.2em', fontWeight: 400 }}>{I18n.t('steps.step1.resumeTitle')}</p>
                <FrameContainer>
                    <MatrixBodies id='tutorial-biometric-matrix-bodies'>
                        <Matriz gender={userSession.gender === 1 ? 'male' : 'female'} matrix={bioMetricMatrixData} />
                    </MatrixBodies>
                    <FrameIndicator id='tutorial-biometric-matrix-data'>
                        {!_.isEmpty(bioMetricMatrixData) && 
                            <>  
                                <Responsive maxWidth={420}>
                                    <Indicators {...this.props} radius={25} />
                                </Responsive>
                                <Responsive minWidth={421}>
                                    <Indicators {...this.props} radius={40} />
                                </Responsive>
                            </>
                        }
                    </FrameIndicator>
                </FrameContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state,'session.time', 0),
        userSession: _.get(state.auth, 'userSession', {}),
        bioMetricMatrixData: _.get(state, 'bioMetricMatrix.bioMetricMatrixData', {})
    };
};

export default connect(mapStateToProps, { openGenericModal, closeGenericModal })(BiometricMatrix);