import styled from 'styled-components/macro';

const FrameContent = styled.div`
    position:relative;
    
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 3rem;
    
`;

const Progress = styled.div`
    max-width:850px;
`;

const ProgressBar = styled.div`

    width:100%;
    overflow:hidden;
    background-color: #D9D9D9;
    height:40px;
    border-radius:5px;

    @media only screen and (max-width:420px) {
        height:30px;
    }

`;

const ProgressInner = styled.div`

    background-color:var(--brand-secondary);
    height:100%;
    width:${({progress}) => `${progress}%` };

`;

const FrameArrow = styled.div`
    position:absolute;
    bottom:0;
    right:0;
`;

export {
    FrameContent,
    Progress,
    ProgressBar,
    ProgressInner,
    FrameArrow
}