// Third party libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

// Reducers
import Reducers from './redux-store';

// Ui components
import App from './ui/app';
import Yloader from './ui/components/loader';
import KeepAlive from './ui/components/keepAlive';

// Others
import { TrackingService } from './utils/TrackingService';

// Styles
import './ui/assets/scss/variables.scss';
import './ui/assets/scss/colors.scss';
import './ui/assets/scss/fonts.scss';
import './ui/assets/scss/typography.scss';
import './ui/assets/scss/slick.scss';
import './ui/assets/scss/helpers.scss';
import './ui/assets/scss/buttons.scss';
import './ui/assets/scss/inputs.scss';
import './ui/assets/scss/generic.scss';
import './ui/assets/scss/interface.scss';

// Third party styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "driver.js/dist/driver.css";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(ReduxThunk))
);

TrackingService.initializeTracking();

const renderReactDom = () => {

    ReactDOM.render(
        <Provider store={ store }>
            <App />
            <Yloader />
            <KeepAlive />
        </Provider>,
        document.getElementById('root')
    );

};

if (window.cordova) {

    document.addEventListener('deviceready', () => {

        window.StatusBar.hide();

        const reducedScreen = Math.max(window.innerHeight, window.innerWidth) < 900;
        if (reducedScreen) {

            window.screen.orientation.lock('portrait');

        } else {

            window.screen.orientation.lock('landscape');

        }

        renderReactDom();

        // Hide semantic scroll in mobile
        const body = document.getElementById('y-body');
        body.classList.add('hideScroll');

        window.addEventListener('keyboardDidShow', keyboardShowHandler);
        window.addEventListener('native.keyboardshow', keyboardShowHandler);
        window.addEventListener('keyboardDidHide', keyboardHideHandler);
        window.addEventListener('native.keyboardhide', keyboardHideHandler);

        function keyboardShowHandler(e) {

            const wrapper = document.getElementsByClassName('wrapper-login')[0];
            if (wrapper) wrapper.style.paddingBottom = (e.keyboardHeight + 60) + 'px';

            const rWrapper = document.getElementsByClassName('r-wrapper')[0];
            if (rWrapper) rWrapper.style.paddingBottom = (e.keyboardHeight + 60) + 'px';

            const profile = document.getElementsByClassName('p-wrapper')[0];
            if (profile) profile.style.height = 'calc(100% - ' + (e.keyboardHeight + 60) + 'px)';

        }

        function keyboardHideHandler(e) {

            const wrapper = document.getElementsByClassName('wrapper-login')[0];
            if (wrapper) wrapper.style.paddingBottom = 0;

            const rWrapper = document.getElementsByClassName('r-wrapper')[0];
            if (rWrapper) rWrapper.style.paddingBottom = 0;

            const profile = document.getElementsByClassName('p-wrapper')[0];
            if (profile) profile.style.height = 'calc(100% - var(--nav-height))';

        }

        // Load inapp store products
        if (!window.store) {

            console.error('Store not loaded correctly');

        } else {

            try {

                const products = [];

                // School products
                for (let i = 1; i < 11; i++) {

                    products.push({ type: window.store.CONSUMABLE, id: `app.yogabot.${ window.device.platform === 'Android' ? 'schoolproduct' : 'schoolProduct' }.${ i }` });

                }

                // Wallet packages
                products.push({ type: window.store.CONSUMABLE, id: 'app.yogabot.ygb.50' });
                products.push({ type: window.store.CONSUMABLE, id: 'app.yogabot.ygb.100' });
                products.push({ type: window.store.CONSUMABLE, id: 'app.yogabot.ygb.150' });
                products.push({ type: window.store.CONSUMABLE, id: 'app.yogabot.ygb.250' });

                // Subscriptions
                products.push({ type: window.store.PAID_SUBSCRIPTION, id: 'app.yogabot.suscription.monthly' });
                products.push({ type: window.store.PAID_SUBSCRIPTION, id: 'app.yogabot.suscription.annual' });

                window.store.register(products);
                window.store.refresh();

            } catch (err) {

                console.error('Error on inapp store products load');

            }

        }

    }, false);

} else {

    renderReactDom();

}