// Third party libraries
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Components
import ProgressBarThumbnails from './progressBarThumbnails';

// Locales
import I18n from '../../../../i18n';

/**
 *
 * @param {Number} startLimitPercent Limita en que porcentaje inicial de la práctica se debe limitar al preview para que no salga de la pantalla
 * @param {Number} endLimitPercent Limita en que porcentaje final de la práctica se debe limitar al preview para que no salga de la pantalla
 * @param {Number} startLimit Determina en que posición se debe parar el preview después que pase el limite de inicio (startLimitPercent)
 * @param {Number} endLimit Determina en que posición se debe parar el preview después que pase el limite de final (endLimitPercent)
 * @param {Function} onForwardAndBackward Función que se ejecuta cuando se hace click en la barra de progreso
 * @returns
 */
const ProgressBarSeeker = ({
  globalProgress,
  globalSequenceTime,
  videos,
  styles,
  startLimitPercent,
  endLimitPercent,
  startLimit,
  endLimit,
  onForwardAndBackward,
  triggerRef,
}) => {
  const [currPreviewPositionX, setCurrPreviewPositionX] = useState(0);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const [currPercentage, setCurrPercentage] = useState(0);

  const [isVisible, setIsVisible] = useState(true);
  const timeoutRef = useRef(null);

  // Función para mostrar el slider
  const showSlider = useCallback(() => {
    if (!triggerRef) return;

    setIsVisible(true);

    // Limpiar cualquier temporizador existente
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Configurar temporizador para ocultar el slider después de 10 segundos
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 10000);
  }, [triggerRef]);

  // Función para reiniciar el temporizador al interactuar con el slider
  const handleSliderInteraction = useCallback(() => {
    if (!triggerRef) return;

    // Limpiar cualquier temporizador existente
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Configurar temporizador para ocultar el slider después de 10 segundos
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  }, [triggerRef]);

  useEffect(() => {
    if (!triggerRef) return;

    if (triggerRef?.current) {
      triggerRef.current.addEventListener('click', showSlider);
    }

    handleSliderInteraction();

    return () => {
      if (triggerRef?.current) {
        triggerRef.current.removeEventListener('click', showSlider);
      }

      // Limpiar el temporizador al desmontar el componente
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [handleSliderInteraction, showSlider, triggerRef]);

  const asanas = useMemo(
    () => videos?.filter((video) => video?.duration !== undefined) || [],
    [videos]
  );

  const totalDuration = useMemo(() => {
    let count = 0;

    if (asanas.length === 0) {
      return 0;
    }

    asanas.forEach((asana) => {
      count += asana.duration || 0;
    });

    return count;
  }, [asanas]);

  const asanasWithPercentage = useMemo(() => {
    if (asanas.length === 0) {
      return [];
    }

    const result = [];

    let count = 0;

    asanas.forEach((asana, index) => {
      const percentage = (asana.duration / totalDuration) * 100;

      // Tuve que restar y sumar 0.5 en cada caso para dar el margen de error que pueda tener javascript
      const percentageStart = (count / totalDuration) * 100 - 0.5;
      const percentageEnd = percentageStart + percentage + 0.5;

      count += asana.duration;

      result.push({
        ...asana,
        currentAsanaIndex: asana?.currentAsanaIndex || index,
        percentageStart,
        percentageEnd,
      });
    });

    return result;
  }, [asanas, totalDuration]);

  const thumbnails = useMemo(() => {
    const thumbnails = [];

    asanasWithPercentage.forEach(async (asana) => {
      const cover = asana.image;

      thumbnails.push({
        cover,
        index: asana?.currentAsanaIndex,
        percentageStart: asana.percentageStart,
        percentageEnd: asana.percentageEnd,
        webm: asana?.webm || asana?.video || null,
      });
    });

    return thumbnails;
  }, [asanasWithPercentage]);

  const renderThumbnails = useMemo(() => {
    if (!thumbnails) return null;

    return thumbnails.map((thumbnail) => {
      const i = thumbnail.index;
      return (
        <ProgressBarThumbnails
          key={'video_' + i}
          index={i}
          display={
            currPercentage >= thumbnail.percentageStart &&
            currPercentage < thumbnail.percentageEnd
              ? 'block'
              : 'none'
          }
          cover={thumbnail.cover}
          originalSrc={thumbnail.webm}
        />
      );
    });
  }, [currPercentage, thumbnails]);

  const getAsanaByPercent = useCallback(
    (percent = 0) =>
      asanasWithPercentage.find(
        (elem) =>
          percent >= elem.percentageStart && percent < elem.percentageEnd
      ),
    [asanasWithPercentage]
  );

  const showPreview = (width, percentage, mouseX)=>{
    if(percentage <= 0 || percentage >= 100) return;

    if (percentage <= startLimitPercent) {
      setCurrPreviewPositionX(width * startLimit);
    } else if (percentage >= endLimitPercent) {
      setCurrPreviewPositionX(width - width * endLimit);
    } else {
      setCurrPreviewPositionX(mouseX);
    }

    setIsPreviewVisible(true);

    setCurrPercentage(percentage);

    handleSliderInteraction();
  }

  const handleOnMouseMove = (event) => {
    const element = event.currentTarget;
    const boundingRect = element.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;

    const width = boundingRect.width;
    const percentage = (mouseX / width) * 100;

    showPreview(width, percentage, mouseX);
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    const element = event.currentTarget;
    const boundingRect = element.getBoundingClientRect();
    const mouseX = touch.clientX - boundingRect.left;

    const width = boundingRect.width;
    const percentage = (mouseX / width) * 100;

    showPreview(width, percentage, mouseX);
  };

  const handleOnMouseLeave = () => {
    setIsPreviewVisible(false);
  };

  const handleOnClick = (event) => {
    const element = event.currentTarget;
    const boundingRect = element.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;

    const width = boundingRect.width;
    const percentage = (mouseX / width) * 100;

    const asana = getAsanaByPercent(percentage);

    if (onForwardAndBackward) {
      let index = videos.findIndex(
        (video) => video.currentAsanaIndex === asana.currentAsanaIndex
      );

      if (index === -1) {
        index = videos.findIndex((video) => video.code === asana.code);
      }

      // Por si después de la segunda búsqueda no encuentra nada, se vuelve a la primera asana
      if (index === -1) index = 0;

      onForwardAndBackward(index);
    }

    handleSliderInteraction();
  };

  return (
    <div
      className="progress-section"
      style={{
        ...styles,
        opacity: !triggerRef ? 1 : isVisible ? 1 : 0,
        pointerEvents: !triggerRef ? 'auto' : isVisible ? 'auto' : 'none',
      }}
    >
      <div
        className="heading-3 upper text"
        dangerouslySetInnerHTML={{ __html: I18n.t('practice.progressLevel') }}
      ></div>
      <div className="progress-bar">
        <div
          className="progress-bar-preview"
          id="progress-bar-preview"
          style={{
            left: `calc(${currPreviewPositionX}px + 5%)`,
            transition:
              currPercentage <= startLimitPercent + 5 ||
              currPercentage >= endLimitPercent - 5
                ? 'left 0.5s ease'
                : undefined,
          }}
          hidden={!isPreviewVisible}
        >
          {renderThumbnails}
        </div>
        <div
          className="progress-bar-seeker"
          onMouseMove={handleOnMouseMove}
          onMouseLeave={handleOnMouseLeave}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleOnMouseLeave}
          onClick={handleOnClick}
        >
          <div
            className="progress-inner"
            style={{ width: (globalProgress / globalSequenceTime) * 100 + '%' }}
          ></div>
        </div>
      </div>
      <div className="heading-3 upper percent">
        {Math.round((globalProgress / globalSequenceTime) * 100)}%
      </div>
    </div>
  );
};

export default ProgressBarSeeker;
