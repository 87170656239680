function useCookies() {
  // Función para establecer una cookie
  function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Definir el tiempo de expiración
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
  }

  // Función para obtener el valor de una cookie
  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return undefined;
  }

  // Función para borrar una cookie
  function eraseCookie(name) {
    setCookie(name, '', -1); // Establecer la cookie con fecha de expiración en el pasado
  }

  return { setCookie, getCookie, eraseCookie };
}

export default useCookies;
