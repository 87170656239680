// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Icon, Message, Responsive } from 'semantic-ui-react';

// Components
import BiometricMatrix from '../../components/biometricMatrix';
import Countdown from '../../components/countdown';
import FixedBanner from '../../components/fixedBanner';
import Footer from '../../components/footer';
import { FooterItem } from '../../components/genericCard/components/simple/styles';
import GenericCard from '../../components/genericCard';
import GradientNav from '../../components/navbar/gradient';
import InAppFirebaseNotification from '../../components/inAppFirebaseNotification/InAppFirebaseNotification';
import Notification from '../../components/notificaction';
import ProgressIndicator from '../../components/progressIndicator';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';
import TutorialStarter from '../../hooks/tutorials/TutorialStarter';

// Redux
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { getChallenges } from '../../../redux-store/challenges';

// Utils
import { askForNotificationsPermission } from '../../../utils/firebase';

// Locales
import I18n from '../../../i18n';

// Styles
import { InnerContainer, FrameBlock, FrameGrid, FramePadded, Wrapper, FramePaddedDiagnosis } from './styles';
import OButton from '../../styled/components/button';

// Tutorial constants
const TutorialSteps = [
    { element: '#tutorial-biometric-matrix-bodies', popover: { title: I18n.t('tutorials.home.biometricMatrixBodies.title'), description: I18n.t('tutorials.home.biometricMatrixBodies.description'), side: "left", align: 'center' } },
    { element: '#tutorial-biometric-matrix-data', popover: { title: I18n.t('tutorials.home.biometricMatrixData.title'), description: I18n.t('tutorials.home.biometricMatrixData.description'), side: "right", align: 'center' } },
    { element: '#tutorial-challenges', popover: { title: I18n.t('tutorials.home.challenges.title'), description: I18n.t('tutorials.home.challenges.description') } },
]

const HeaderMessage = props => {

    let availableChallengeIndex = _.findIndex(_.get(props,'challenges', []), challenge => !challenge.locked);
    if (availableChallengeIndex > -1) {

        if (props.challenges[availableChallengeIndex].asanas[0].selectedValue < 1) {

            //reto disponible y primer ejercicio pristine
            return (
                <React.Fragment>
                    <div className="right">
                        {!props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.firstTimeMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                        {props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.redoMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                    </div>
                </React.Fragment>
            );

        } else {

            //reto disponible y primer ejercicio no pristine
            return  (
                <React.Fragment>
                    <div className="right">
                        {!props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.continueMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', '')}) }}></p>}
                        {props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.redoMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                    </div>
                </React.Fragment>
            );

        }

    } else {

        return (
            <React.Fragment>
                <div className="right">
                    {props.role.indexOf('premium') > -1 ? <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloquePremiumFirst`, { userName:  _.get(props,'name', '')}) }}></p> : <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloqueSuscripterFirst`, { userName:  _.get(props,'name', '')}) }}></p>}
                    {props.role.indexOf('premium') > -1 ? <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloquePremiumSecond`) }}></p> : <p className="paragraph regular">¿Has valorado pasarte a Premium? Además de poder realizar tu autodiagóstico cada 7 días, cuentas con otras muchas ventajas y funcionalidades. Vale la pena.</p>}
                    <p className="paragraph regular">El sistema de autodiagnóstico quedará disponible en:</p>
                    {props.nextUnlockDate && <Countdown timeTillDate={props.nextUnlockDate} timeFormat="YYYY-MM-DDHH:mm:ss" classes="fitted" onEnd={props.onEnd}/>}
                </div>
            </React.Fragment>
        );

    }

}

class Home extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            toggled: true,
            
            tutorialSteps: [],
        }

    }

    componentDidMount() {

        this.setState({ tutorialSteps: TutorialSteps });

        askForNotificationsPermission(_.get(this.props.userSession, 'notificationTokens', []));

    }

    selectChallenge = challengeId => this.props.history.push(`/diagnosis/challenge/${challengeId}`);

    onEnd = () => this.onGetChallenges();

    onGetChallenges = async () => {

        try {

            await this.props.getChallenges();

        } catch (error) { }

    }

    render() {

        const { bioMetricMatrix, userSession, challenges, role} = this.props;
        const { tutorialSteps } = this.state;
        const nextChallengeToGo = _.findIndex(_.get(challenges, 'challenges'), challenge => !challenge.locked);

        const configCard = {
            type: 'simple',
            mode: 'normal',
            list: challenges.challenges,
            mainAction: (item, index) => { this.selectChallenge(index) },
            isAllowed: item => !item.locked,
            renderTitle: item => item.description,
            renderDescription: item => item.subtitle,
            renderIndicator: item => item.title,
            renderFooterItems: item => <FooterItem className="caption regular">{ `${ I18n.t('challenge.meassures') } ${ item.positionCount } | ${ item.time } Min ${ I18n.t('challenge.aproxDuration') }` }</FooterItem>,
            thumbnail: { attribute : 'cover', ratio: '56.25%' },
            sliderToGo: nextChallengeToGo
        };

        return (
            <React.Fragment>
                <GradientNav active="home" {...this.props} />
                <Wrapper>
                    <TopBar {...this.props} />
                    <FixedBanner />
                    <InnerContainer>
                        <FrameBlock>
                            <FramePadded>
                                <p className="heading-1" style={{ marginBottom: '5px' }}>{I18n.t('steps.step2.title')}</p>
                            </FramePadded>
                        </FrameBlock>
                        <FrameBlock>
                            <FramePadded>
                                <ProgressIndicator progress={ bioMetricMatrix.progress || 0 } title={I18n.t('steps.step2.progress.title')} progressText={ I18n.t('steps.step2.progress.matrix', { progress: bioMetricMatrix.progress || 0 }) } nextChallengeToGo={nextChallengeToGo} onSelectChallenge={this.selectChallenge}/>
                            </FramePadded>
                        </FrameBlock>
                        <FrameBlock>
                            <FramePadded>
                                <BiometricMatrix />
                            </FramePadded>
                        </FrameBlock>
                        <FrameBlock>
                            <FramePadded>
                                <Message className="c-pointer" size='tiny' onClick={()=> this.props.history.push(`/home/guide`)} compact><Icon name="question circle outline" size="large" /><span className="caption">{I18n.t('messages.whatIsBiometricMatrix')}</span></Message>
                            </FramePadded>
                        </FrameBlock>
                        <FrameBlock>
                            <FramePadded>
                                <FrameGrid>
                                    <FrameBlock>
                                        <FramePaddedDiagnosis>
                                            <div style={{padding: '20px 0'}}>
                                                <p className="heading-3" style={{fontWeight: 'lighter'}}>{I18n.t('steps.step2.text')}</p>
                                                <HeaderMessage name={ _.get(userSession,'name', '') } redoing={_.get(challenges, 'redoing', false)} role={_.get(role, 'role', '')} nextUnlockDate={_.get(challenges,'nextUnlockDate', '')} challenges={_.get(challenges,'challenges', [])} onEnd={this.onEnd} />
                                                <Responsive as={React.Fragment} maxWidth={420}>
                                                    <OButton
                                                        upper
                                                        color='#fff'
                                                        primary
                                                        style={{ marginTop: '1rem' }}
                                                        onClick={()=> this.selectChallenge(nextChallengeToGo)}
                                                    >
                                                        <span style={{ fontWeight: 'lighter', fontSize: 12 }}>{ I18n.t('actions.completeMatriz') }</span>
                                                    </OButton>
                                                </Responsive>
                                                <Responsive as={React.Fragment} minWidth={421}>
                                                    <OButton
                                                        upper
                                                        color='#fff'
                                                        primary
                                                        style={{ marginTop: '1rem' }}
                                                        onClick={()=> this.selectChallenge(nextChallengeToGo)}
                                                    >
                                                        <span style={{ fontWeight: 'lighter', fontSize: 16 }}>{ I18n.t('actions.completeMatriz') }</span>
                                                    </OButton>
                                                </Responsive>
                                            </div>
                                            <div id='tutorial-challenges'>
                                                <GenericCard {...configCard} />
                                            </div>
                                        </FramePaddedDiagnosis>
                                    </FrameBlock>
                                </FrameGrid>
                            </FramePadded>
                        </FrameBlock>
                        <Footer {...this.props} type="branded"/>
                    </InnerContainer>
                </Wrapper>
                <SubMenu active="home" {...this.props}  />
                <Notification type="fixered"/>
                { window.cordova && <InAppFirebaseNotification notificationTokens={ _.get(userSession, 'notificationTokens', []) } /> }
                {
                    <TutorialStarter name='home' steps={ tutorialSteps } />
                }
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state,'session.time', 0),
        userSession: _.get(state.auth, 'userSession', {}),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        challenges: _.get(state, 'challenges', []),
        role: _.get(state, 'role', {}),
    };

};

export default connect(mapStateToProps, { getChallenges, openLoader, closeLoader })(Home);