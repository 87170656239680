import React from 'react'
import { FrameContent, Progress, ProgressBar, ProgressInner} from './styles';
import OButton from '../../styled/components/button';
import I18n from '../../../i18n';
import { Responsive } from 'semantic-ui-react';

/***
 * * PROGRESS INDICATOR
 * 
 * @param title String translated
 * @param progress Number 0 => 100
 * @param progressText String translated
 * @param callback Function optional, If the callback is defined then the caret icon box appear and call the callback with true/false params
 * @description  
 */

export default function ProgressIndicator ({ title = "", progress = 0, progressText = "", nextChallengeToGo = 0, onSelectChallenge = ()=> {} }) {

    return (
        <div>
            <p style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 400 }}>{title}</p>
            <FrameContent>
                <Progress>
                    <ProgressBar>
                        <ProgressInner progress={progress}/>
                    </ProgressBar>
                </Progress>
                {/* <span className="paragraph regular">{progressText}</span> */}
                <Responsive as={React.Fragment} maxWidth={420}>
                    <OButton
                        upper
                        color='#fff'
                        primary
                        onClick={ ()=> onSelectChallenge(nextChallengeToGo) }
                    >
                        <span style={{ fontWeight: 'lighter', fontSize: 12 }}>{ I18n.t('actions.completeMatriz') }</span>
                    </OButton>
                </Responsive>
                <Responsive as={React.Fragment} minWidth={421}>
                    <OButton
                        upper
                        color='#fff'
                        primary
                        onClick={ ()=> onSelectChallenge(nextChallengeToGo) }
                    >
                        <span style={{ fontWeight: 'lighter', fontSize: 16 }}>{ I18n.t('actions.completeMatriz') }</span>
                    </OButton>
                </Responsive>
            </FrameContent>
        </div>
    )
}
