// Third party libraries
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Flag, Icon, Image, Modal } from 'semantic-ui-react';
import I18nIsoCountries from 'i18n-iso-countries';
import I18nIsoLanguages from '@cospired/i18n-iso-languages';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Assets
import DemoImage from '../../assets/img/yogabot_icon.svg';

try {

    _.each(I18n.languages, language => {

        I18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${ language }.json`));
        I18nIsoLanguages.registerLocale(require(`@cospired/i18n-iso-languages/langs/${ language }.json`));

    });

} catch (e) {

    console.error('Error while loading locale files', e);

}

const ProModal = ({ pro, open, onClose }) => {

    const getInitials = text => (text.substring(0, 1) + (text.includes(' ') ? text.substring(text.indexOf(' ') + 1, text.indexOf(' ') + 2) : '')).toUpperCase();
    const getLanguages = languages => _.map(languages, language => I18nIsoLanguages.getName(language, I18n.language)).join(' - ');

    const [proInfo, setProInfo] = useState({});

    useEffect(() => {

        if (pro) {

            const newData = pro;
            pro.initials = getInitials(_.get(proInfo, 'name', '') + ' ' + _.get(proInfo, 'surname', ''));
            pro.languagesText = getLanguages(_.get(pro, 'languages', []));
            setProInfo(newData);

        }

    }, [open]);

    return (
        <Modal
            closeIcon
            onClose={ onClose }
            open={ open }
            size='tiny'
        >
            <Modal.Content scrolling>
                <div className='proImage'>
                    <span className={ `initials ${ !!!_.get(proInfo, 'pictureUrl') ? 'visible' : ''}` } >{ proInfo.initials }</span>
                    <Image avatar src={ _.get(proInfo, 'pictureUrl', DemoImage) } className={ !!!_.get(pro, 'pictureUrl') ? 'broken' : '' } onError={ e => { e.target.classList.add('broken'); e.target.src = DemoImage } } centered size='small' />
                </div>
                <span className='title'>{ _.get(proInfo, 'name', '') + ' ' + _.get(proInfo, 'surname', '') }</span>
                { _.isEmpty(proInfo.comercialName) ? '':
                    <div className='subtitle comercialName'>
                        <Icon name='building' />
                        <span>{ proInfo.comercialName }</span>
                    </div>
                }
                <div className='subtitle location'>
                    <Flag name={ _.get(proInfo, 'country', 'es') } />
                    <span>{ _.get(proInfo, 'city', '') + ' - ' + I18nIsoCountries.getName(_.get(proInfo, 'country', 'es'), I18n.language) }</span>
                </div>
                { _.isEmpty(proInfo.languages) ? '' :
                    <div className='subtitle language'>
                        <Icon name='language' />
                        <span>{ proInfo.languagesText }</span>
                    </div>
                }
                <span className='text'>{ _.get(proInfo, 'about', '') }</span>
                { _.isEmpty(proInfo.videoUrl) ? '' : proInfo.videoUrl.includes('vimeo') ?
                    <iframe title='vimeo' src={ proInfo.videoUrl + '?title=0&byline=0&portrait=0&dnt=1&end=0&loop=1' } width='100%' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen style={{aspectRatio: '16 / 9'}}></iframe>
                    : <video src={ proInfo.videoUrl } controls controlsList='nodownload noremoteplayback'>Tu navegador no soporta videos</video>
                }
                { _.isEmpty(proInfo.ssmm) ? '' :
                    <div className='actions'>
                        { _.isEmpty(proInfo?.ssmm?.facebook) ? '' : <Button color='facebook' onClick={ () => window.open(proInfo.ssmm.facebook, '_blank') } ><Icon name='facebook' /><span className='buttonLabel'> Facebook</span></Button> }
                        { _.isEmpty(proInfo?.ssmm?.instagram) ? '' : <Button color='instagram' onClick={ () => window.open(proInfo.ssmm.instagram, '_blank') } ><Icon name='instagram' /><span className='buttonLabel'> Instagram</span></Button> }
                        { _.isEmpty(proInfo?.ssmm?.web) ? '' : <Button onClick={ () => window.open(proInfo.ssmm.web, '_blank') } ><Icon name='world' /><span className='buttonLabel'> Web</span></Button> }
                    </div>
                }
            </Modal.Content>
        </Modal>
    );

}

export default ProModal;